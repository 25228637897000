import React from 'react';
import { Layout } from '../../components/shared/Layout';

const BewonersPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>Voor bewoners</h1>
            <p>
              Lever je organisch afval in, draag bij aan een circulaire stad en
              spaar voor korting op mooie groene producten.
            </p>

            <p>
              In Den Haag wordt organisch afval (GFT) in grote delen van de stad
              niet apart opgehaald. Tijd om daar verandering in aan te brengen!
            </p>

            <p>
              Doe mee aan Haagsche Schil en lever je organisch afval in. Zo
              draag je bij aan een duurzamere en circulaire stad, en spaar je
              voor korting op groene producten.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default BewonersPage;
